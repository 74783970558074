<template>
  <div class="content">
    <div class="row">
      <div class="col-4">
        <input type="text" class="form-control" placeholder="Name" v-model="name" />
      </div>
      <div class="col-4">
        <input type="text" class="form-control" placeholder="slug" v-model="slug" disabled />
      </div>
      <div class="col">
        <button class="vave-btn btn-blue" @click="createStrategy()" :disabled="!this.name">Create</button>
      </div>
      <div class="col-auto">
        <router-link :to="{ name: 'strategy-ordering-docs' }" class="mb-4 vave-btn">Documentation</router-link>
      </div>
    </div>
    <div class="table-container">
      <table class="table align-middle">
        <thead class="head">
          <tr>
            <th class="ps-4">Id</th>
            <th>Name</th>
            <th class="text-center">Default</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="strategy in strategiesList" :key="strategy.id">
            <td class="ps-4">{{strategy.id}}</td>
            <td>{{strategy.name}}</td>
            <td class="text-center">
              <input type="checkbox" :checked="strategy.is_default" @change="setDefault(strategy.id)" :disabled="strategy.is_default == true" />
            </td>
            <td>
              <div class="d-flex">
                <router-link class="w-full vave-btn" :to="'/ordering-strategies/' + strategy.id">
                  <i class="fa-regular fa-pen-to-square me-1"></i>
                  Edit
                </router-link>
                <div class="vave-btn btn-outline-red ms-2" @click="deleteAction(strategy.id)">
                  <i class="fa-regular fa-trash"></i>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "../http.js";

export default {
  data() {
    return {
      name: "",
      strategiesList: [],
    };
  },
  computed: {
    slug() {
      return this.name.toLowerCase().replace(/ /g, "-");
    },
  },
  mounted() {
    this.setup();
  },
  methods: {
    setDefault(id) {
      // find the strategy in strategiesList
      let strategy = this.strategiesList.find((strategy) => {
        return strategy.id == id;
      });
      strategy.is_default = true;
      axios.put("/api/admin/ordering-strategies/" + id, strategy).then(() => {
        this.getStrategies();
      });
    },
    setup() {
      if (this.strategiesList.length <= 0) {
        this.getStrategies();
      }
    },
    deleteAction(id) {
      if (!confirm("do you want to delete the strategy " + id + "?")) {
        return;
      }
      axios.delete("/api/admin/ordering-strategies/" + id).then(() => {
        this.getStrategies();
      });
    },
    getStrategies() {
      axios.get("/api/admin/ordering-strategies").then((response) => {
        if (response.data.data) {
          this.strategiesList = response.data.data;
        }
      });
    },
    createStrategy() {
      axios
        .post("/api/admin/ordering-strategies", {
          name: this.name,
          slug: this.slug,
        })
        .then(
          (response) => {
            this.name = "";
            this.getStrategies();
            if (response.data.data.id) {
              this.$router.push(
                "/ordering-strategies/" + response.data.data.id
              );
            }
          },
          (e) => {
            console.log(e.response.data);
            alert(e.response.data.message);
          }
        );
    },
  },
};
</script>

<style lang="scss" scoped>
tbody tr {
  &:hover {
    background: #fafafa;
  }
}
</style>