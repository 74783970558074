import { render, staticRenderFns } from "./OrderingStrategies.vue?vue&type=template&id=46132ff4&scoped=true&"
import script from "./OrderingStrategies.vue?vue&type=script&lang=js&"
export * from "./OrderingStrategies.vue?vue&type=script&lang=js&"
import style0 from "./OrderingStrategies.vue?vue&type=style&index=0&id=46132ff4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46132ff4",
  null
  
)

export default component.exports